import tokens from "./tokens";

//NOTE ALL ADRESSES NEED TO BE CHECKSUMMED
const farms = [
  {
    pid: 1,
    lpSymbol: "PINE-ATROPA",
    lpAddress: "0x0E4B3d3141608Ebc730EE225666Fd97c833d553E",
    token: tokens.atropa,
    quoteToken: tokens.pine,
    version: "1",
    farmActive: true,
  },
  {
    pid: 2,
    lpSymbol: "PINE-pDAI",
    lpAddress: "0xa1cE806C501b08072bdfe48d50C3d84b3C5a4fc3",
    token: tokens.dai,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 3,
    lpSymbol: "PINE-pWBTC",
    lpAddress: "0x310c77c20B5b7872a20E7e29079B7a62016d52ba",
    token: tokens.wbtc,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 4,
    lpSymbol: "PINE-WPLS",
    lpAddress: "0xA97a6FA76A8D27d43Bc94d050fD92372F269eAC0",
    token: tokens.wpls,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 5,
    lpSymbol: "PINE-PLSX",
    lpAddress: "0x4B798587Bf68b5b5DC4a5a63A7567DA825235d29",
    token: tokens.plsx,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 6,
    lpSymbol: "PINE-pAAVE",
    lpAddress: "0x494a8878F614207736a0b2dBF3cB6a4Ee3dA8eba",
    token: tokens.paave,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 7,
    lpSymbol: "PINE-pLINK",
    lpAddress: "0x0466f1E0Ce98c4F515c5ee9b0898064b2fEf8cE0",
    token: tokens.plink,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 8,
    lpSymbol: "PINE-pMKR",
    lpAddress: "0x44447b77daA07fb6eFde23269eb9e28101348Fba",
    token: tokens.pmkr,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 9,
    lpSymbol: "PINE-eHEX",
    lpAddress: "0x145FA4465e2ddC20cdE2a3ca6071Ecbcb1144624",
    token: tokens.ehex,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 10,
    lpSymbol: "PINE-pUSDC",
    lpAddress: "0xaEEDBEFAF43165556F595aDA0C7bF181E535BD0D",
    token: tokens.usdc,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 11,
    lpSymbol: "PINE-pUSDT",
    lpAddress: "0x57ae75975eBaF4C937ff21F35cB5426fF4a1661A",
    token: tokens.usdt,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 12,
    lpSymbol: "PINE-pWETH",
    lpAddress: "0xAd5Ae07a7BC821986cD34c0554b20166b7Da370C",
    token: tokens.weth,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 13,
    lpSymbol: "PINE-BEAR",
    lpAddress: "0x7549C2595Fe6309987e079200bc5a542bD41e7dA",
    token: tokens.bear,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 14,
    lpSymbol: "PINE-DOWN",
    lpAddress: "0xa73288548eC3349D73A2502983A2AABC25ac549c",
    token: tokens.down,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 15,
    lpSymbol: "PINE-TSFI",
    lpAddress: "0x4607E21e978C675e8242CB00c12dDE5D326b8C23",
    token: tokens.tsfi,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
  {
    pid: 16,
    lpSymbol: "PINE-STABLE-LP",
    lpAddress: "0x373dA0F7Fcd8E5AEa89D34540b8F3da16B27fA34",
    token: tokens.stable,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
    {
    pid: 17,
    lpSymbol: "PINE-pBAL",
    lpAddress: "0xa51e20880876d56459f49ad3250b1b4da2371be9",
    token: tokens.pbal,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
    {
    pid: 18,
    lpSymbol: "PINE-pHEX",
    lpAddress: "0x4e848c2d48ee1e25b4d69ebff566377b5ad3e035",
    token: tokens.phex,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
    {
    pid: 19,
    lpSymbol: "PINE-pCRV",
    lpAddress: "0x3ee183908c0a9ad3093d354159504b887c3acd9a",
    token: tokens.pcrv,
    quoteToken: tokens.pine,
    version: "2",
    farmActive: true,
  },
];

export default farms;
