import tokens from './tokens'
import atropineMasterchefABI from '../abi/masterchef.json';
import regularMasterchefABI from '../abi/regularMasterchef.json';
import nineInchMasterchefABI from '../abi/nineInchMasterchef.json';
import sparkSwapMasterchefABI from '../abi/sparkSwapMasterchefABI.json'

//NOTE ALL ADRESSES NEED TO BE CHECKSUMMED
const masterchefs = {
  atropine: {
    name: 'Atropine',
    address: '0x6A621e240BA80B90090981D71E535C5148EFA450',
    abi: atropineMasterchefABI,
    rewardToken: tokens.pine,
    dexLink: 'https://bafybeianerj3xzphshwneafkkgbrca5bqcslpdjixcjb6wj3frmvpbrdki.ipfs.dweb.link/#/add/V',
    farmLink: 'https://atropine.io/farms',
    rewardTokenPair: '0x0E4B3d3141608Ebc730EE225666Fd97c833d553E',
    tokenPerBlockFunctionName: 'PinePerBlock'
  },
  nineInch: {
    name: '9Inch',
    address: '0x444775Ae2C82560337c86f6D62909a63381De4fd',
    abi: nineInchMasterchefABI,
    rewardToken: tokens.bbc,
    dexLink: 'https://app.9inch.io/add/',
    farmLink: 'https://app.9inch.io/farms/',
    rewardTokenPair: '0xb543812ddEbC017976f867Da710ddb30cCA22929',
    tokenPerBlockFunctionName: 'bbcPerBlock'
  },
  pulsex: {
    name: 'PulseX',
    address: '0xB2Ca4A66d3e57a5a9A12043B6bAD28249fE302d4',
    abi: regularMasterchefABI,
    rewardToken: tokens.inc,   
    dexLink: 'https://bafybeianerj3xzphshwneafkkgbrca5bqcslpdjixcjb6wj3frmvpbrdki.ipfs.dweb.link/#/add/V',
    farmLink: 'https://bafybeianerj3xzphshwneafkkgbrca5bqcslpdjixcjb6wj3frmvpbrdki.ipfs.dweb.link/#/farms',
    rewardTokenPair: '0xf808Bb6265e9Ca27002c0A04562Bf50d4FE37EAA',
    tokenPerBlockFunctionName: 'incPerSecond'
  },
  sparkswap: {
    name: 'SparkSwap',
    address: '0x2c8BBd2cecC77F2d18A04027Cc03CDB8Ab103214',
    abi: sparkSwapMasterchefABI,
    rewardToken: tokens.spark,
    dexLink: 'https://exchange.sparkswap.xyz/#',
    farmLink: 'https://sparkswap.xyz/#/farms',
    rewardTokenPair: '0x1B044593a78E374bD0E558Aa6633D2ff13fD5Bb7',
    tokenPerBlockFunctionName: 'sparkPerBlock'
  },
}

const farms = [
 /* //atropine pools
  {
    masterchef: masterchefs.atropine,
    pid: 1,
    lpSymbol: 'PINE-ATROPA',
    lpAddress:'0x0E4B3d3141608Ebc730EE225666Fd97c833d553E',
    token: tokens.atropa,
    quoteToken: tokens.pine,
    version: '1',
    farmActive: true,
    autoCompound: ''
  },
  {
    masterchef: masterchefs.atropine,
    pid: 2,
    lpSymbol: 'PINE-pDAI',
    lpAddress: '0xa1cE806C501b08072bdfe48d50C3d84b3C5a4fc3',
    token: tokens.dai,
    quoteToken: tokens.pine,
    version: '2',
    farmActive: true,
    autoCompound: ''
  },
  {
    masterchef: masterchefs.atropine,
    pid: 3,
    lpSymbol: 'PINE-pWBTC',
    lpAddress: '0x310c77c20B5b7872a20E7e29079B7a62016d52ba',
    token: tokens.wbtc,
    quoteToken: tokens.pine,
    version: '2',
    farmActive: true,
    autoCompound: ''

  },
  {
    masterchef: masterchefs.atropine,
    pid: 4,
    lpSymbol: 'PINE-WPLS',
    lpAddress: '0xA97a6FA76A8D27d43Bc94d050fD92372F269eAC0',
    token: tokens.wpls,
    quoteToken: tokens.pine,
    version: '2',
    farmActive: true,
    autoCompound: ''
  },
  {
    masterchef: masterchefs.atropine,
    pid: 5,
    lpSymbol: 'PINE-PLSX',
    lpAddress: '0x4B798587Bf68b5b5DC4a5a63A7567DA825235d29',
    token: tokens.plsx,
    quoteToken: tokens.pine,
    version: '2',
    farmActive: true,
    autoCompound: ''
  },
*/
  //pulsex pools
  {
    masterchef: masterchefs.pulsex,
    pid: 1,
    lpSymbol: 'eDAI-WPLS',
    lpAddress: '0xE56043671df55dE5CDf8459710433C10324DE0aE',
    token: tokens.wpls,
    quoteToken: tokens.edai,
    version: '1',
    farmActive: true,
    autoCompound: '0xE4825F2397556D705d41B9B741eEa82eb1b55f8b'
  },
  {
    masterchef: masterchefs.pulsex,
    pid: 0,
    lpSymbol: 'PLSX-WPLS',
    lpAddress: '0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9',
    token: tokens.plsx,
    quoteToken: tokens.wpls,
    version: '1',
    farmActive: true,
    autoCompound: '0x6db650FB81F59B6BE32a28f539aA962b29765CB0'
  },
  {
    masterchef: masterchefs.pulsex,
    pid: 2,
    lpSymbol: 'eUSDC-WPLS',
    lpAddress: '0x6753560538ECa67617A9Ce605178F788bE7E524E',
    token: tokens.eusdc,
    quoteToken: tokens.wpls,
    version: '1',
    farmActive: true,
    autoCompound: '0xDf8bC639ADe968239517C9164f64b1253ca28019'
  },
  {
    masterchef: masterchefs.pulsex,
    pid: 4,
    lpSymbol: 'eWETH-WPLS',
    lpAddress: '0x42AbdFDB63f3282033C766E72Cc4810738571609',
    token: tokens.eth,
    quoteToken: tokens.wpls,
    version: '1',
    farmActive: true,
    autoCompound: '0x2Ac86E1e84EA7413595c884F570a83aFFE914996'
  },
  {
    masterchef: masterchefs.pulsex,
    pid: 5,
    lpSymbol: 'eUSDT-WPLS',
    lpAddress: '0x322Df7921F28F1146Cdf62aFdaC0D6bC0Ab80711',
    token: tokens.eusdt,
    quoteToken: tokens.wpls,
    version: '1',
    farmActive: true,
    autoCompound: '0x61d574c692712b1f81CE49d476C398EDDd91a669'
  },
  {
    masterchef: masterchefs.pulsex,
    pid: 7,
    lpSymbol: 'HEX-WPLS',
    lpAddress: '0xf1F4ee610b2bAbB05C635F726eF8B0C568c8dc65',
    token: tokens.hex,
    quoteToken: tokens.wpls,
    version: '1',
    farmActive: true,
    autoCompound: '0x77C507bd7f89e0F99b36BA1942255f0EE64f8f1f'
  },
  {
    masterchef: masterchefs.pulsex,
    pid: 8,
    lpSymbol: 'INC-WPLS',
    lpAddress: '0xf808Bb6265e9Ca27002c0A04562Bf50d4FE37EAA',
    token: tokens.inc,
    quoteToken: tokens.wpls,
    version: '1',
    farmActive: true,
    autoCompound: '0xFf665228104f2de67a901073b8E8F26FD5e0221F'
  },
  {
    masterchef: masterchefs.pulsex,
    pid: 9,
    lpSymbol: 'INC-PLSX',
    lpAddress: '0x7Dbeca4c74d01cd8782D4EF5C05C0769723fb0ea',
    token: tokens.inc,
    quoteToken: tokens.plsx,
    version: '1',
    farmActive: true,
    autoCompound: '0xdB67D5Cb46a0B4e8b2aB8BA93c7052fB0fEFaebF'
  },

  //9inch pools
  {
    masterchef: masterchefs.nineInch,
    pid: 2,
    lpSymbol: '9INCH-eDAI',
    lpAddress: '0x31AcF819060AE711f63BD6b682640598E250C689',
    token: tokens.nineinch,
    quoteToken: tokens.edai,
    version: '',
    farmActive: true,
    autoCompound: '0xCbc630FaF06F3d2B8789dfc6B54d8E17b7689377',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 1,
    lpSymbol: '9INCH-WPLS',
    lpAddress: '0x1164daB36Cd7036668dDCBB430f7e0B15416EF0b',
    token: tokens.nineinch,
    quoteToken: tokens.wpls,
    version: '',
    farmActive: true,
    autoCompound: '0x4b0B09808Ecd74a1C64f3780Ef7991cAE1EEee2e',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 3,
    lpSymbol: '9INCH-eUSDC',
    lpAddress: '0x6C5a0F22b459973A0305e2a565fc208a35A13850',
    token: tokens.nineinch,
    quoteToken: tokens.eusdc,
    version: '',
    farmActive: true,
    autoCompound: '0xcf908cc0A75BFC3f6897a5df4327dc57517569b4',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 4,
    lpSymbol: '9INCH-eUSDT',
    lpAddress: '0x5449a776797B55A4AaC0B4A76B2AC878BFF3D3e3',
    token: tokens.nineinch,
    quoteToken: tokens.eusdt,
    version: '',
    farmActive: true,
    autoCompound: '0x0e32050750efBB6fe145322ad8c6Ec22Cdcd9De1',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 0,
    lpSymbol: '9INCH-BBC',
    lpAddress: '0xb543812ddEbC017976f867Da710ddb30cCA22929',
    token: tokens.nineinch,
    quoteToken: tokens.bbc,
    version: '',
    farmActive: true,
    autoCompound: '0x16A5006612400aE227403D6DA88e4d11D1F2a21f',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 6,
    lpSymbol: '9INCH-W9INCH',
    lpAddress: '0x097d19b2061C5f31B68852349187C664920b4BA4',
    token: tokens.nineinch,
    quoteToken: tokens.wnineinch,
    version: '',
    farmActive: true,
    autoCompound: '0x60894f4aFd31C3c2a42691daD06643fed7044ba0',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 7,
    lpSymbol: '9INCH-PP',
    lpAddress: '0x37515F5F2bb8a45Ae7CceF8653762a28cc8d4e3E',
    token: tokens.nineinch,
    quoteToken: tokens.pp,
    version: '',
    farmActive: true,
    autoCompound: '0x6F500EdAc715aF2e806E122F51aDd33Cc4B2F0D9',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 8,
    lpSymbol: 'WPLS-PLD',
    lpAddress: '0x330a3a0a05f8F3Cc7Bb1372e98EcaCa75fcbDc69',
    token: tokens.wpls,
    quoteToken: tokens.pld,
    version: '',
    farmActive: true,
    autoCompound: '0x36398cf7c1D2e627bB0806fC8Ef9f7a84E16B39F',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 9,
    lpSymbol: '9INCH-PLD',
    lpAddress: '0x0030176f066CE0929A31ea87697Dc51c715f8bEf',
    token: tokens.nineinch,
    quoteToken: tokens.pld,
    version: '',
    farmActive: true,
    autoCompound: '0xa0459a5CD0abfff819Fb1986e54cc2f248089Cc5',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 10,
    lpSymbol: 'WPLS-WHETH',
    lpAddress: '0x9F7578Fef8c1Fba986d2db83e4b1Fa5F1ECb7324',
    token: tokens.wpls,
    quoteToken: tokens.wheth,
    version: '',
    farmActive: true,
    autoCompound: '0x6F25E472e4A29Abba756850dfEDB491F517683df',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 11,
    lpSymbol: '9INCH-WHETH',
    lpAddress: '0x828cc8423fc97817CaD78C8bFBbA26923444dF60',
    token: tokens.nineinch,
    quoteToken: tokens.wheth,
    version: '',
    farmActive: true,
    autoCompound: '0xefe6C6542D48567472855b40a7a29f1A588a2aCA',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 12,
    lpSymbol: 'WPLS-PP',
    lpAddress: '0x329958477eaa4BFC9A036BA620a7E71dE75d44D4',
    token: tokens.wpls,
    quoteToken: tokens.pp,
    version: '',
    farmActive: true,
    autoCompound: '0x594Cb02BD0947aacE25c39d7C8CA215F8f0e7F4C',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 13,
    lpSymbol: 'WPLS-PLSX',
    lpAddress: '0xB89ea01677529A8bbCECE3a486404880f9463C45',
    token: tokens.wpls,
    quoteToken: tokens.plsx,
    version: '',
    farmActive: true,
    autoCompound: '0x79e85d2FC2629686F9BbFF48f3B7dF43fB4Bf567',
  },
  {
    masterchef: masterchefs.nineInch,
    pid: 14,
    lpSymbol: '9INCH-PLSX',
    lpAddress: '0x898BB93f4629c73f0c519415a85d6BD2977CB0b5',
    token: tokens.nineinch,
    quoteToken: tokens.plsx,
    version: '',
    farmActive: true,
    autoCompound: '0x970b52203c85491BeeE295211FeE43E6410075fc',
  },


  //SparkSwap POOLS
  {
    masterchef: masterchefs.sparkswap,
    pid: 0,
    lpSymbol: 'SPARK-WPLS',
    lpAddress: '0x1B044593a78E374bD0E558Aa6633D2ff13fD5Bb7',
    token: tokens.spark,
    quoteToken: tokens.wpls,
    version: '',
    farmActive: true,
    autoCompound: '0xe4757d3589cB03720eA6C5E1Cf02904B2Cb47B44',
  },
  {
    masterchef: masterchefs.sparkswap,
    pid: 12,
    lpSymbol: 'ETH-EMP',
    lpAddress: '0xd3300F0Bd587f0B9A77b62F74EA26FcA1086618b',
    token: tokens.eth,
    quoteToken: tokens.emp,
    version: '2',
    farmActive: true,
    autoCompound: '0xA910965f3D7213656764b2a0A88Dff3fcDF4c1Fb',
  },
  {
    masterchef: masterchefs.sparkswap,
    pid: 13,
    lpSymbol: 'sDAI-DAI',
    lpAddress: '0xf32B9398a7277609772F328Fc2005D7DA5420E77',
    token: tokens.sdai,
    quoteToken: tokens.edai,
    version: '2',
    farmActive: true,
    autoCompound: '0x5B36c304320d6dDEBa1C1528d58DE699Af9e886A',
  },
  {
    masterchef: masterchefs.sparkswap,
    pid: 15,
    lpSymbol: 'sDAI-USDEX+',
    lpAddress: '0xf3a8a528c6300Cf1E70762c3695B9C60B5a79e15',
    token: tokens.sdai,
    quoteToken: tokens.emp,
    version: '2',
    farmActive: true,
    autoCompound: '0x33762466648B06C32a1e34e30c6bcCECc5367044',
  },
]

export default farms
